button:focus,
button:hover{
	outline: none!important;
	box-shadow: none!important;
  text-decoration: none!important;
}
.searchform{
  top: -200px;
  margin-top: -16.5rem;
}

.main-banner .slide-item {
  /* height: auto; */
  height: 92vh;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  /* object-fit: fill; */
}

.main-banner .slide-item .section-padding {
  padding: 120px 0;
}

.group-form .form-control-custom,
.group-form .form-control-custom:not(:last-child) {
  border-radius: 50px !important;
}

.banner-tabs .tab-pane .row {
  margin: 0 -10px;
}

.banner-tabs .tab-content {
  padding-bottom: calc(20px - 1rem);
}

.banner-tabs label.submit+button {
  margin-bottom: 1rem;
}

.banner-tabs .custom-select {
  margin: 0;
}

.banner-tabs .form-group label.submit+button {
  margin-bottom: 0;
}

.section-padding.section {
  padding-bottom: 50px;
}

.our-team .team-block .team-social-box {
  transform: none;
  top: auto;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
  transition: 0.3s all;
}

.our-team .team-block .team-social-box li {
  left: 0;
  margin-bottom: 0;
  width: 25%;
  display: flex;
}

.our-team .team-block .team-social-box li a {
  width: 100%;
  border-radius: 0;
  line-height: normal;
  display: block;
  padding: 10px 0;
  background: #01b7f2;
  color: #fff;
}

.our-team .team-block .designation {
  margin-bottom: 10px;
}

.our-team .team-block .lower-content {
  border: none;
  display: inline-block;
  width: 100%;
}

.our-team .team-block:hover .team-social-box {
  transform: translateY(-20px);
}

.about-us .about-right-side .about-img:before {
  top: auto;
  bottom: 0;
}

.video_btn {
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  font-size: 22px;
}

.section-header .section-description .subheading {
  font-size: 22px;
  font-family: 'Poppins', sans-serif;
}

.section-header .section-heading h3:before {
  content: "\f072";
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  color: #01b7f2;
  font-size: 16px;
  position: absolute;
  bottom: -8.5px;
}

.section-header .section-heading h3 {
  position: relative;
}

.section-header.style-right .section-heading h3::before {
  transform: rotate(180deg);
  right: 0;
}

.testimonial-box .testimonial-wrapper .testimonial .rating {
  margin-bottom: 15px;
  font-size: 16px;
}

.testimonial-box .testimonial-wrapper .testimonial:before {
  position: relative;
  padding: 0;
  display: flex;
  margin-bottom: 15px;
}

.testimonial-box .testimonial-wrapper .testimonial .author strong {
  font-size: 16px;
  color: #01b7f2;
}
.pagination_layout{
	margin-top: -30px;
}
.blog-details .post .post-wrapper .blog-meta .post-meta-box .post-categories a::after{
  content: ', ';
}
.blog-details .post .post-wrapper .blog-meta .post-meta-box .post-categories a:last-child::after{
  display: none;
}
.comment-box .comments li article .comment-avatar{
  width: 80px;
}
.sidebar_wrap .sidebar .sidebar_widgets .popular_post li:last-child{
  margin-bottom: 0;
}
.sidebar_wrap .sidebar .sidebar_widgets .tags{
  margin-bottom: -10px;
}
.listing-details-inner .listing-meta-sec .listing-testimonial .tesimonial-item .testimonial-author .author-img{
  width: 86px;
  height: 86px;
}
.htmltext p:last-child{
  margin-bottom: 0;
}
.hotel-grid .hotel-grid-wrapper.car-grid .image-sec img{
  object-fit: cover;
}
@media (max-width: 992px) {
  .section-header.style-right .section-heading h3::before {
    transform: none;
    right: auto;
  }
}
.card .card-header{
  display: flex;
  flex-basis: 100%;
  order: 1;
  border-radius: 0;
}
.card .collapseparent{
  order: 2;
}
.accordion .card .collapseparent + .card-header .btn-link{
background: #ededed;
  border-color: #ededed;
  color: #000;
}
.accordion .card .collapseparent + .card-header .btn-link::before{
content: '\f067';
  background: #2d3e52;
  color: #ededed;
  font-family: "Font Awesome 5 Pro";
  padding: 0;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.accordion .card .collapseparent.show + .card-header .btn-link{
background: #01b7f2;
  color: #fff;
border-color: transparent;
}
.accordion .card .collapseparent.show + .card-header .btn-link::before{
background: #fff;
  color: #01b7f2;
content: '\f068';
}
ul li,
ul{
  list-style: none;
  padding: 0;
  margin: 0;
}
.group-form .custom-select:before{
  pointer-events: none;
}
.group-form .custom-select{
  padding-right: 0;
}
.custom-select select{
  appearance: none;
  border: 0;
  background: transparent;
  width: 100%;
}

.currency{
  left: 990px;
  font-size: larger;
  font-weight: 800;
  position: absolute;
  color:#EE6767;
}

@media (max-width: 992px) {
  .currency{
    left: 230px;
    font-size: larger;
    font-weight: 600;
    position: absolute;
    color:#EE6767;
  }
}
.centered-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-top: 20px;
  color:#596d73;
}

.visible-button {
  visibility: visible;
}